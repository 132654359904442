import useAnalyticsContext from '@hooks/useAnalyticsContext';
import BlankButton from '@xo-union/component-blank-button';
import Icon from '@xo-union/tk-component-icons';
import React, { FunctionComponent } from 'react';
import Styles from './styles.scss';

export interface Props {
	onClick: () => void;
	setHoverClear: (status: boolean) => void;
}

export const ClearButton: FunctionComponent<Props> = (props) => {
	const { onClick, setHoverClear } = props;
	const { track } = useAnalyticsContext();

	const handleClick = () => {
		onClick();
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action: 'clear location',
				sourcePage: 'category results',
			},
		});
	};

	return (
		<BlankButton
			onClick={handleClick}
			className={Styles.clearButtonContainer}
			onMouseEnter={() => setHoverClear(true)}
			onMouseLeave={() => setHoverClear(false)}
		>
			<Icon size="md" name="close_circle" className={Styles.icon} />
		</BlankButton>
	);
};

ClearButton.displayName = 'ClearButton';

export default ClearButton;
