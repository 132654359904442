import { compose } from '@xo-union/react-css-modules';
import { ALL_EVENT_TYPES, NOOP } from '../constants';
import type { ContextBaseValue, Props } from '../types/context';
import parseLocation from '../utilities/parseLocation';
import { boolean } from '../utilities/primitives';
import { isContextLargeProps } from '../utilities/typeGuards';

/**
 * Extract the values needed for Context from the props provided.
 *
 * @param {VendorCardProps} props - The props provided to the vendor card.
 * @return {Context.VendorCardPropsValues} - The values to be applied to Context value.
 */
const buildContextBaseValue = (props: Props): ContextBaseValue => {
	const badges = isContextLargeProps(props) && props.badges ? props.badges : [];
	const dek = props.dek || {};
	const ctaLabel = props.ctaLabel || 'Request Quote';
	const handleEvent = props.handleEvent || NOOP;
	const handleEventTypes = props.handleEventTypes || ALL_EVENT_TYPES;
	const classes = props.classes || compose({});
	const hideRequestPricing = props.hideRequestPricing || false;
	const isFavorited = props.isFavorited || false;
	const lazyLoadImages = boolean(props.lazyLoadImages, props.lazyLoadImage);
	const imageRatio = props.imageRatio || 2 / 3;
	const name = props.name || '';
	const onClickBadge = props.onClickBadge || NOOP;
	const onClickFavorite = props.onClickFavorite || NOOP;
	const onClickCard = props.onClickCard || NOOP;
	const onClickCarousel = props.onClickCarousel || NOOP;
	const onClickRequestPricing = props.onClickRequestPricing || NOOP;
	const preserveBrokenImages = props.preserveBrokenImages || false;
	const maxImageCount = props.maxImageCount || 5;
	const reviewCount = props.reviewCount || 0;
	const secondaryInfo = props.secondaryInfo || [];
	const serviceArea = props.serviceArea || '';
	const starCount = props.starCount || 0;
	const url = props.url || '';
	const size = props.size || 'lg';
	const vendorLocation =
		props.vendorLocation || parseLocation(props.location) || '';
	const isMobile = props.isMobile || false;
	const preload = props.preload || false;
	const quickResponder = props.quickResponder || false;

	return {
		badges,
		ctaLabel,
		classes,
		dek,
		handleEvent,
		handleEventTypes,
		hideRequestPricing,
		imageRatio,
		isFavorited,
		lazyLoadImages,
		maxImageCount,
		name,
		onClickBadge,
		onClickCard,
		onClickCarousel,
		onClickFavorite,
		onClickRequestPricing,
		preserveBrokenImages,
		reviewCount,
		secondaryInfo,
		serviceArea,
		size,
		starCount,
		vendorLocation,
		url,
		isMobile,
		preload,
		quickResponder,
	};
};

export default buildContextBaseValue;
