import { ClassesRecord, compose } from '@xo-union/react-css-modules';
import { OptionClasses, SelectClasses } from '@xo-union/tk-component-fields';

import Styles from './styles.scss';

export const selectClasses = compose<
	SelectClasses,
	ClassesRecord<SelectClasses>
>({
	container: Styles.selectContainer,
	input: Styles.selectInput,
	caret: Styles.selectCaret,
	list: Styles.selectList,
});

export const optionClasses = compose<
	OptionClasses,
	ClassesRecord<OptionClasses>
>({
	container: Styles.optionContainer,
	checkmark: Styles.optionCheckmark,
});
