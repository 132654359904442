import { ClassesRecord, compose } from '@xo-union/react-css-modules';
import { AsyncTypeaheadClasses } from '@xo-union/tk-component-fields';

import Styles from './styles.scss';

export const asyncTypeaheadClasses = compose<
	AsyncTypeaheadClasses,
	ClassesRecord<AsyncTypeaheadClasses>
>({
	container: Styles.typeaheadContainer,
	list: Styles.typeaheadList,
	'list-container': Styles.typeaheadListContainer,
});
