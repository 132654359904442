import type { SecondaryVendorFacet } from '../../../types/extract';
import type { SecondaryVendorFacetBuild } from '../../../types/internal';

export const findFacet = (facetId: string, vendor?: Vendor.Raw) =>
	vendor?.facets?.find((facet) => facet.id === facetId);

const getFacetName = (facet?: Vendor.Facet | Vendor.SubFacet | ''): string => {
	if (typeof facet === 'undefined' || facet === '') {
		return '';
	}
	return facet.name || '';
};

const getChild = (facet: Vendor.Facet | '') => {
	if (facet === '') {
		return '';
	}

	return facet.facets[0];
};

const getFacet = (
	facet: Vendor.Facet | '',
	build: SecondaryVendorFacetBuild,
): string => {
	if (facet === '') {
		return '';
	}
	if (build.subFacet) {
		const subFacet = facet.facets.find((f) => f.id === build.subFacet);
		return getFacetName(subFacet);
	}
	return getFacetName(getChild(facet));
};

const handleOptionObject = (
	facet: Vendor.Facet | '',
	option: SecondaryVendorFacetBuild,
): string => {
	const facetString = getFacet(facet, option);
	if (option.format) {
		return option.format(facetString);
	}
	return facetString;
};

const parseFacet = (facet: Vendor.Facet | '', option: SecondaryVendorFacet) => {
	if (typeof option === 'string') {
		return getFacetName(getChild(facet));
	}
	return handleOptionObject(facet, option);
};

const secondaryInfo = (
	vendor: Vendor.Raw,
	secondaryInfoFacets?: SecondaryVendorFacet[],
): string[] => {
	const options = secondaryInfoFacets || [];
	const results = options
		.map((option) => {
			const facetId = typeof option === 'string' ? option : option.facetId;
			const facet = findFacet(facetId, vendor) || '';

			return parseFacet(facet, option);
		})
		.filter((result) => result !== '');

	return results;
};

export default secondaryInfo;
