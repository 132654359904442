import { useUniqueID } from '@xo-union/react-unique-id';
import React, {
	Children,
	cloneElement,
	createContext,
	forwardRef,
	isValidElement,
	useEffect,
	useRef,
} from 'react';
import mergeRefs from '../../../utils/mergeRefs';
import { DEFAULT_PROPS_CONTEXT } from '../constants';
import { Props, Value } from '../types/context';
import * as symbols from '../utilities/symbols';
import buildContextBaseValue from './buildContextBaseValue';
import * as messages from './messages';
import { MutableContextProvider } from './mutableContext';

const ContextValue = createContext<Value>(DEFAULT_PROPS_CONTEXT);

export const PropsContextProvider = forwardRef<HTMLDivElement, Props>(
	(props, refFromConsumer) => {
		const baseContextValue = buildContextBaseValue(props);

		const id = useUniqueID(props.id);

		// Set Hooks
		const localRef = useRef<HTMLDivElement>(null);

		// Other setup
		const cardRef = mergeRefs<HTMLDivElement>(refFromConsumer, localRef);

		useEffect(() => {
			messages.deprecatedWarnings(props);
			messages.missingProps(props);
		}, [props]);

		const value: Value = {
			...baseContextValue,
			id,
			allMedia: props.media || [],
		};

		const kids = Children.map(props.children, (child) => {
			if (symbols.isForwardRef(child) && isValidElement(child)) {
				return cloneElement(child, { ref: cardRef });
			}
			return child;
		});

		return (
			<ContextValue.Provider value={value}>
				<MutableContextProvider>{kids}</MutableContextProvider>
			</ContextValue.Provider>
		);
	},
);

export default ContextValue;
