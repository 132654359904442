import type { Value } from '../../types/context';
import type { TrackingEvent } from '../../types/events';

interface HandleClickOptions {
	props: Value;
	event: TrackingEvent;
}

export const handleClick = (options: HandleClickOptions): void => {
	const {
		event,
		props: {
			onClickCarousel,
			onClickRequestPricing,
			onClickFavorite,
			onClickCard,
		},
	} = options;
	if (event.type !== 'click') {
		return;
	}

	switch (event.name) {
		case 'request': {
			onClickRequestPricing(event.vendorId);
			break;
		}
		case 'favorite': {
			onClickFavorite(event.vendorId, event.properties.action === 'select');
			break;
		}
		case 'card': {
			onClickCard(event.vendorId);
			break;
		}
		case 'carousel': {
			const {
				properties: { activeIndex, direction },
			} = event;
			onClickCarousel(activeIndex, direction);
			break;
		}
		default: {
			// Do nothing
		}
	}
};
