import React, { Fragment, FunctionComponent } from 'react';
import Category from './Category';
import Location from './Location';
import SearchByVendor from './SearchByVendor';

interface Props {
	isSearchByVendor: boolean;
	changeCode: (code: string) => void;
	defaultCityState: string;
	code: string | null;
	handleLocationSelection: (location: Union.Location) => void;
	checkUserInput: () => void;
	locationHash: Union.Location | null;
}

const InnerSearch: FunctionComponent<Props> = (props) => {
	const {
		locationHash,
		checkUserInput,
		isSearchByVendor,
		changeCode,
		defaultCityState,
		code,
		handleLocationSelection,
	} = props;

	if (isSearchByVendor) {
		return <SearchByVendor />;
	}

	return (
		<Fragment>
			<Category code={code} setCode={changeCode} />
			<Location
				defaultCityState={defaultCityState}
				setLocationHash={handleLocationSelection}
				checkUserInput={checkUserInput}
				locationHash={locationHash}
			/>
		</Fragment>
	);
};

export default InnerSearch;
