import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { useContext, VFC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';

const StarCount: VFC = () => {
	const { starCount } = useContext(propsContext);
	const className = useClassName('star-count');

	return (
		<span className={className}>
			{starCount}
			<ScreenReaderOnly>Stars</ScreenReaderOnly>
		</span>
	);
};

export default StarCount;
