import styles from '../../../css/vendorCard.scss';
import type { VendorCardClasses } from '../../../types/classes';
import type { Size } from '../../../types/utils';
import * as findClassFor from './findClassFor';

const buildDefaultClasses = (size: Size): Partial<VendorCardClasses> => ({
	'badge-container': styles.badgeContainer,
	'badge-item': styles.badgeItem,
	container: styles.container,
	content: findClassFor.content(size),
	'favorite-button': styles.favoriteButton,
	'image-slide': styles.imageSlide,
	'image-container': findClassFor.imageContainer(size),
	'image-default-link': styles.imageDefaultLink,
	'image-skeleton-container': styles.imageSkeletonContainer,
	'info-container': styles.infoContainer,
	'is-favorited-icon': styles.isFavoritedIcon,
	location: styles.location,
	'location-text': styles.locationText,
	'not-favorited-icon': styles.notFavoritedIcon,
	'request-pricing': styles.requestPricing,
	'request-pricing-wrapper': styles.requestPricingWrapper,
	'review-container': styles.reviewContainer,
	'review-count': styles.reviewCount,
	'secondary-info': styles.secondaryInfo,
	'secondary-info-bullet': styles.secondaryInfoBullet,
	'secondary-info-container': styles.secondaryInfoContainer,
	stars: styles.stars,
	'star-count': styles.starCount,
	subtext: styles.subtext,
	'vendor-name': styles.vendorName,
	'default-image-brand-logo': styles.defaultImageBrandLogo,
	'default-image-container': styles.defaultImageContainer,
	'default-image-label': styles.defaultImageLabel,
});

export default buildDefaultClasses;
