import { Checkbox, MultiSelectGroup } from '@xo-union/tk-component-switches';
import { Caption } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React from 'react';

import Styles from '../../styles.scss';

const MultiSelectOptions = ({ options, selected, setSelected }) => {
	const handleChange = (e) => {
		const { value } = e.target;
		const updatedSelection = [...selected];
		if (updatedSelection.includes(value)) {
			updatedSelection.splice(updatedSelection.indexOf(value), 1);
		} else {
			updatedSelection.push(value);
		}

		setSelected(updatedSelection);
	};

	return (
		<MultiSelectGroup>
			{options.map((option) => (
				<div className={Styles.option} key={`option_${option.value}`}>
					<Checkbox
						checked={selected.includes(option.value)}
						onChange={handleChange}
						value={option.value}
					>
						{option.name}
						{option.subtext && (
							<Caption className={Styles.caption}>{option.subtext}</Caption>
						)}
					</Checkbox>
				</div>
			))}
		</MultiSelectGroup>
	);
};

MultiSelectOptions.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			subtext: PropTypes.string,
		}),
	).isRequired,
	selected: PropTypes.arrayOf(PropTypes.string).isRequired,
	setSelected: PropTypes.func.isRequired,
};

export default MultiSelectOptions;
