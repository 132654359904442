import classnames from 'classnames';
import React, { Fragment, useEffect, useRef, useState, VFC } from 'react';
import { connect } from 'react-redux';
import SearchBy from '../../SearchBy';
import InnerSearch from '../InnerSearch';
import Submit from '../Submit';
import { getDefaults, getPromptTerm } from '../helpers';
import Styles from '../styles.scss';

export type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
	setContainerSize?: (size: number) => void;
}

export type DesktopSearchProps = OwnProps & StateProps;

const DesktopSearch: VFC<DesktopSearchProps> = (props) => {
	const { appliedFilterPills, category, location, setContainerSize } = props;
	const { code: categoryCode } = category;
	const defaults = getDefaults(location);
	const searchRef = useRef(null);

	const [locationHash, setLocationHash] = useState<Union.Location | null>(
		defaults.locationHash,
	);
	const [isSearchByVendor, setIsSearchByVendor] = useState(false);
	const [code, setCode] = useState<string>(categoryCode);
	const [locationFieldDirty, setLocationFieldDirty] = useState<boolean>(false);

	useEffect(() => {
		calculateWidth();
		if (window) {
			window.addEventListener('resize', calculateWidth);
			return () => {
				window.removeEventListener('resize', calculateWidth);
			};
		}
		return () => {
			// Do nothing
		};
	}, []);

	useEffect(() => {
		calculateWidth();
	}, [appliedFilterPills.length]);

	const calculateWidth = () => {
		if (searchRef.current) {
			const style = window.getComputedStyle(searchRef.current);
			const width = style?.getPropertyValue('width');
			if (width && setContainerSize) {
				const size = parseInt(width.replace('px', ''), 10);
				setContainerSize(size);
			}
		}
	};

	const changeCode = (codeChange: string) => {
		// Will need analytics
		setCode(codeChange);
	};

	const checkUserInput = () => {
		if (locationHash === null) {
			return;
		}

		if (locationFieldDirty) {
			setLocationHash(null);
		} else {
			setLocationHash(null);
			setLocationFieldDirty(true);
		}
	};

	const toggleSearchByVendor = () => {
		// do we want analytics
		setIsSearchByVendor(!isSearchByVendor);
	};

	const innerSearchContainerClasses = classnames(
		Styles.innerSearchContainer,
		isSearchByVendor ? Styles.innerSearchVendor : undefined,
	);
	return (
		<Fragment>
			<div className={Styles.searchContainer} ref={searchRef}>
				<div className={innerSearchContainerClasses}>
					<InnerSearch
						code={code}
						locationHash={locationHash}
						checkUserInput={checkUserInput}
						isSearchByVendor={isSearchByVendor}
						changeCode={changeCode}
						defaultCityState={defaults.cityState}
						handleLocationSelection={setLocationHash}
					/>
				</div>
				<Submit
					isSearchByVendor={isSearchByVendor}
					locationHash={locationHash}
					code={code}
				/>
			</div>
			<SearchBy
				className={Styles.searchBy}
				promptTerm={getPromptTerm(category)}
				sourcePage="category results"
				onClick={toggleSearchByVendor}
				searchByVendor={isSearchByVendor}
			/>
		</Fragment>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	appliedFilterPills: state.search.filterPills.applied,
	category: state.category,
	location: state.location,
});

export default connect(mapStateToProps)(DesktopSearch);
