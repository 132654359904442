import Icon from '@xo-union/tk-component-icons';
import React, { FunctionComponent, Fragment } from 'react';

import Styles from './styles.scss';

export interface Props {
	city: string;
	stateCode: string;
}

const Suggestion: FunctionComponent<Props> = (props) => {
	const { city, stateCode } = props;

	return (
		<Fragment>
			<Icon size="md" name="address" className={Styles.suggestionIcon} />
			<div className={Styles.suggestionLabel}>
				{city}, {stateCode}
			</div>
		</Fragment>
	);
};

export default Suggestion;
