import type { Size } from '../../types';

export interface GetMediaConfig {
	media: Vendor.Media[];
	size: Size;
	maxImageCount: number;
	invalidIds: string[];
}

export interface GetMedia {
	(config: GetMediaConfig): Vendor.PhotoMedia[];
}

export const getMedia: GetMedia = (config) => {
	const { media, size, maxImageCount, invalidIds } = config;
	const validMedia = media
		.filter((item): item is Vendor.PhotoMedia => item.mediaType === 'PHOTO')
		.filter((m) => !m.id || !invalidIds.includes(m.id))
		.map((item) => ({ ...item, url: `${item.url}` }));

	if (size === 'lg') {
		return maxImageCount === 0
			? validMedia
			: validMedia.slice(0, maxImageCount);
	}
	return validMedia.slice(0, 1);
};
