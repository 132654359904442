import { Button } from '@xo-union/tk-component-buttons';
import { H3 } from '@xo-union/tk-ui-typography';
import React, { Fragment } from 'react';
import SearchByVendor from '../../../SearchByVendor';
import Styles from './styles.scss';

const SearchByVendorName = () => (
	<Fragment>
		<H3 className={Styles.title}>Search By Vendor Name</H3>
		<SearchByVendor />
		<Button
			className={Styles.vendorNameSearchButton}
			color="primary"
			size="md"
			disabled
		>
			Search
		</Button>
	</Fragment>
);

export default SearchByVendorName;
