import type { IconName } from '@xo-union/dist-iconography';
import { Option } from '@xo-union/tk-component-fields';
import Icon from '@xo-union/tk-component-icons';
import React, { Fragment, FunctionComponent } from 'react';
import * as classes from './classes';
import Styles from './styles.scss';

export interface Props {
	category: Category.Category;
	label: string;
}

const SelectOption: FunctionComponent<Props> = (props) => {
	const { category, label } = props;
	const iconName = `category-${category.code.toLowerCase()}`;
	return (
		<Option
			classes={classes.optionClasses}
			key={category.code}
			id={`option-${category.code}`}
			value={category.code}
			label={category.name}
		>
			<Fragment>
				<div className={Styles.optionIcon}>
					<Icon name={iconName as IconName} size="md" />
				</div>
				<div className={Styles.optionLabel}>{label}</div>
			</Fragment>
		</Option>
	);
};

export default SelectOption;
