import NavigableModal, {
	NavigableModalProps,
} from '@components/NavigableModal/NavigableModal';
import { getPromptTerm } from '@components/shared/Search/helpers';
import SearchBy from '@components/shared/SearchBy';
import React, { useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'types/redux';
import SearchByLocation from './SearchByLocation';
import SearchByVendorName from './SearchByVendorName';
import Styles from './styles.scss';

export interface ChangeSearchModalProps
	extends Omit<NavigableModalProps, 'hash'> {
	closeCallback: () => void;
	isMobile?: boolean;
	isInSemanticSearch?: boolean;
}

type SearchType = 'location' | 'vendor name';

const ChangeSearchModal: VFC<ChangeSearchModalProps> = (props) => {
	const { closeCallback, isMobile = false, isInSemanticSearch = false } = props;
	const [searchType, setSearchType] = useState<SearchType>('location');
	const toggleSearchType = () =>
		setSearchType(searchType === 'location' ? 'vendor name' : 'location');
	const category = useSelector((state: State) => state.category);

	return (
		<NavigableModal
			hash="change-search-modal"
			isModalOpen={props.isModalOpen}
			closeCallback={closeCallback}
			className={Styles.container}
			size="md"
		>
			{searchType === 'location' && (
				<SearchByLocation
					title={isInSemanticSearch ? 'Category & Location' : 'Search'}
				/>
			)}
			{searchType === 'vendor name' && <SearchByVendorName />}
			{!isInSemanticSearch && (
				<div className={Styles.toggleSearchType}>
					<SearchBy
						isMobile={isMobile}
						promptTerm={getPromptTerm(category)}
						sourcePage="category results"
						onClick={toggleSearchType}
						searchByVendor={searchType === 'vendor name'}
					/>
				</div>
			)}
		</NavigableModal>
	);
};

export default ChangeSearchModal;
