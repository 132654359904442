import Icon from '@xo-union/tk-component-icons';
import { Caption } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './styles.scss';

const QuickResponderBadge = () => (
	<div className={Styles.quickResponderBadge}>
		<Icon name="timeline" size="sm" />
		<Caption size="lg" className={Styles.quickResponderBadgeText}>
			Responds quickly
		</Caption>
	</div>
);

export default QuickResponderBadge;
