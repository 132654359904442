import type { ExtractVendorProps } from '../../types';
import parseLocation from '../../utilities/parseLocation';
import extract from './extract';

const extractVendorProps: ExtractVendorProps = (
	vendor,
	secondaryInfoFacets,
) => {
	const badges = extract.badges(vendor);
	const dek = vendor.dek || {};
	const id = vendor.id || '';
	const name = vendor.name || '';
	const reviewCount = vendor.reviewSummary?.count || 0;
	const secondaryInfo = extract.secondaryInfo(vendor, secondaryInfoFacets);
	const media = extract.vendorMedia(vendor);
	const starCount = extract.starCount(vendor);
	const url = extract.url(vendor);
	const { serviceArea } = vendor.location;
	const vendorLocation = parseLocation(vendor.location?.address);
	const quickResponder = Boolean(vendor.vendorBehavior?.quickResponder);

	return {
		badges,
		dek,
		id,
		media,
		name,
		quickResponder,
		reviewCount,
		secondaryInfo,
		serviceArea,
		starCount,
		url,
		vendorLocation,
	};
};

export default extractVendorProps;
