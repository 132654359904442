import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Button } from '@xo-union/tk-component-buttons';
import Icon from '@xo-union/tk-component-icons';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import locationToString from '../../../utils/locationToString';
import { buildSearchRef } from './helpers';
import Styles from './styles.scss';

export interface Props {
	categories: Redux.Categories;
	locationHash: Union.Location | null;
	code: string;
	isSearchByVendor: boolean;
}

const Submit: FunctionComponent<Props> = (props) => {
	const { categories, code, locationHash, isSearchByVendor } = props;
	const { track } = useAnalyticsContext();

	const searchRef = buildSearchRef(categories, code, locationHash);

	const handleSearchSubmit = () => {
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action: 'search completed',
				vendorMarket: locationToString(locationHash),
				sourcePage: 'category results',
				vendorCategoryCode: code,
			},
		});
		window.location.href = searchRef;
	};

	return (
		<Button
			aria-label="search"
			className={Styles.submit}
			color="primary"
			disabled={searchRef === '' || isSearchByVendor}
			onClick={handleSearchSubmit}
			type="button"
		>
			<Icon
				size="md"
				name="search"
				className={Styles.submitIcon}
				aria-hidden="true"
			/>
		</Button>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	categories: state.categories,
});

export default connect(mapStateToProps)(Submit);
