import { H3 } from '@xo-union/tk-ui-typography';
import React, { FC } from 'react';
import Typeaheads from '../../../../SearchWidget/Typeaheads/CategoryLocationTypeaheads';
import Styles from './styles.scss';

interface SearchByLocationProps {
	title: string;
}

const SearchByLocation: FC<SearchByLocationProps> = ({ title }) => (
	<>
		<H3 className={Styles.title}>{title}</H3>
		<Typeaheads />
	</>
);

export default SearchByLocation;
