import { VendorCardLarge, extractVendorProps } from '@components/vendorCard';
import {
	buildCardClickEvent,
	buildCarouselEvent,
} from '@components/vendorResult/track';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import useIsVisible from '@hooks/useIsVisible';
import * as VendorImpressionThunks from '@redux/vendorImpressions/thunks';
import React, { useContext, useEffect, useMemo, VFC } from 'react';
import { connect } from 'react-redux';
import RFQModalContext from '../../contexts/RFQModalContext/RFQModalContext';
import buildFacetIds from './buildFacetIds';
import type { ScrollDirection } from './types';

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = {
	queueVendorImpression: (
		vendorDetails: VendorImpressions.ImpressionDetails,
	) => void;
};

type OwnProps = {
	impressionType: VendorImpressions.ImpressionType;
	index: number;
	isSaved: boolean;
	maxImageCount: number;
	onEnter?: () => void;
	onExit?: () => void;
	onSaveHandler(vendor: Vendor.Raw): void;
	rawVendor: Vendor.Raw;
	cardUiLocation?: string;
};

export type ResultCardProps = StateProps & DispatchProps & OwnProps;

const ResultCard: VFC<ResultCardProps> = (props) => {
	const {
		cardUiLocation,
		impressionType,
		index,
		isMobile,
		maxImageCount,
		isSaved,
		conversations,
		onEnter,
		onExit,
		onSaveHandler,
		queueVendorImpression,
		rawVendor,
		trackingProps,
	} = props;
	const facetIds = buildFacetIds(rawVendor);
	const vendorProps = extractVendorProps(rawVendor, facetIds);
	const { handleCTAClick } = useContext(RFQModalContext);

	const maxCarouselImages = useMemo(
		() => (isMobile ? 10 : maxImageCount),
		[isMobile, maxImageCount],
	);
	const callback = () => {
		queueVendorImpression(impressionDetails);
	};

	const { track } = useAnalyticsContext();

	const [cardRef] = useIsVisible<HTMLDivElement>({ callback });

	const ctaLabel = conversations[rawVendor.id]
		? 'View Conversation'
		: 'Request Quote';

	useEffect(() => {
		const element = cardRef.current;
		if (!element) {
			return;
		}
		if (onEnter) {
			element.addEventListener('mouseenter', onEnter);
			element.addEventListener('focus', onEnter);
		}
		if (onExit) {
			element.addEventListener('mouseleave', onExit);
			element.addEventListener('blur', onExit);
		}
		return () => {
			if (onEnter) {
				element.removeEventListener('mouseenter', onEnter);
				element.removeEventListener('focus', onEnter);
			}
			if (onExit) {
				element.removeEventListener('mouseleave', onExit);
				element.removeEventListener('blur', onExit);
			}
		};
	}, [cardRef.current, onEnter, onExit]);

	const onClickCard = () => {
		const trackingEvent = buildCardClickEvent({
			vendor: rawVendor,
			index,
			props: trackingProps,
			badges: vendorProps.badges,
		});

		track(trackingEvent);
	};

	const onClickFavorite = () => {
		onSaveHandler(rawVendor);
	};

	const onClickRequestPricing = () => {
		handleCTAClick(rawVendor, 'Vendor Card CTA');
	};

	const onClickCarousel = (
		nextImageIndex: number,
		scrollDirection: ScrollDirection,
	) => {
		const event = buildCarouselEvent({
			vendor: rawVendor,
			index: nextImageIndex,
			scrollDirection,
		});
		track(event);
	};

	const impressionDetails: VendorImpressions.ImpressionDetails = {
		cardUiLocation,
		impressionType,
		locationIndex: index + 1,
		vendor: rawVendor,
	};

	return useMemo(() => {
		return (
			<VendorCardLarge
				{...vendorProps}
				ctaLabel={ctaLabel}
				lazyLoadImages={isMobile ? index > 2 : index > 6} // Lazy load all cards after the first 2 on mobile, and first two rows on desktop
				maxImageCount={maxCarouselImages}
				isFavorited={isSaved}
				onClickCard={onClickCard}
				onClickCarousel={onClickCarousel}
				onClickFavorite={onClickFavorite}
				onClickRequestPricing={onClickRequestPricing}
				ref={cardRef}
				isMobile={isMobile}
				preload={index === 0}
			/>
		);
	}, [
		vendorProps,
		cardRef,
		isMobile,
		isSaved,
		ctaLabel,
		index,
		maxCarouselImages,
		onClickCard,
		onClickCarousel,
		onClickFavorite,
		onClickRequestPricing,
	]);
};

const mapStateToProps = (state: Redux.State) => ({
	isMobile: state.viewport.isMobile,
	conversations: state.messagedVendors.conversations,
	trackingProps: {
		currentPage: state.search.pagination.page,
		filters: state.filters,
		isReferred: state.settings.isReferred,
		marketCode: state.settings.marketCode,
		referrer: state.requestInfo.info.referrer,
		sortType: state.search.sort,
		sortVariation: state.search.sortVariation,
		sortVersion: state.search.sortVersion,
	},
});

const mapDispatchToProps = {
	queueVendorImpression: VendorImpressionThunks.queueVendorImpression,
};

export default connect<StateProps, DispatchProps, OwnProps>(
	mapStateToProps,
	mapDispatchToProps,
)(ResultCard);
