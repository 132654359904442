import React, { Fragment, FunctionComponent } from 'react';
import Media from './Media';
import Pagination from './Pagination';

const Images: FunctionComponent = () => (
	<Fragment>
		<Media />
		<Pagination />
	</Fragment>
);

export default Images;
