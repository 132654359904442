import { connect } from 'react-redux';

import { fetchPvRecAnonVendors } from './actions';
import PVRecWidgetContainer from './components/pvrec_widget.jsx';
import algorithms from './constants/algorithms';

export function mapStateToProps(state) {
	const recommendedVendors = [];

	recommendedVendors[algorithms.PVREC_ANON] =
		state.widget.pvRecAnonVendorsReducer.recommendedVendors;

	return {
		category: state.category,
		recommendedVendors,
		maxResultColumns: state.search.maxResultColumns,
	};
}

export const mapDispatchToProps = {
	fetchPvRecAnonVendors,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PVRecWidgetContainer);
