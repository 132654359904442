import type { Size } from '@components/vendorCard/types';
import { useAppSelector } from '@redux/hooks';
import type { Media, PhotoMedia } from '@typings/vendor';
import type { Raw } from '@typings/vendor';
import { useMemo } from 'react';

export interface AiPhotoDetails {
	aiPhoto: PhotoMedia | null;
	optedIn: boolean;
	vendorPhoto: PhotoMedia | null;
}

type TransformMedia = (media: PhotoMedia[], vendors: Raw) => PhotoMedia[];

type UseOrderedMedia = (
	media: Media[],
	maxCount: number,
	size: Size,
	id: string,
) => PhotoMedia[];

type SizedMedia = (
	media: PhotoMedia[],
	maxCount: number,
	size: Size,
) => PhotoMedia[];

type FilterMedia = (media: Media[]) => PhotoMedia[];

const transformMedia: TransformMedia = (media, vendor) => {
	const { ai, settings } = vendor;
	const aiPhoto = ai?.photo;
	const hasOptedIn = settings?.AIPhoto;

	if (hasOptedIn && aiPhoto) {
		const mediaWithoutAiPhoto = media.filter(
			(photo) => photo.id !== aiPhoto?.id,
		);
		return [aiPhoto, ...mediaWithoutAiPhoto];
	}

	return media;
};

const sizedMedia: SizedMedia = (media, maxCount, size) => {
	if (size === 'lg') {
		return maxCount === 0 ? media : media.slice(0, maxCount);
	}
	return media.slice(0, 1);
};

const filterMedia: FilterMedia = (media) => {
	const validMedia = media.filter(
		(item): item is PhotoMedia => !!item.id && item.mediaType === 'PHOTO',
	);
	return validMedia;
};

export const useOrderedMedia: UseOrderedMedia = (media, maxCount, size, id) => {
	const vendors = useAppSelector((state) => state.search.vendors);
	const vendor = useMemo(() => vendors.find((v) => v.id === id), [vendors, id]);

	const orderedMedia = useMemo(() => {
		const filteredMedia = filterMedia(media);
		if (!vendor) {
			return sizedMedia(filteredMedia, maxCount, size);
		}
		const transformedMedia = transformMedia(filteredMedia, vendor);
		return sizedMedia(transformedMedia, maxCount, size);
	}, [maxCount, media, size, vendor]);

	return orderedMedia;
};
