import useAnalyticsContext from '@hooks/useAnalyticsContext';
import VendorTypeahead from '@xo-union/tk-component-vendor-typeahead';
import { debounce } from 'lodash';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Raw } from 'types/vendor';
import { getPromptTerm } from '../helpers';
import Suggestion from './Suggestion';
import * as classes from './classes';

interface Props {
	category: Redux.Category;
}

const SearchByVendor: FunctionComponent<Props> = (props) => {
	const { category } = props;
	const { track } = useAnalyticsContext();

	useEffect(() => {
		const inputEl = document.getElementById('search-by-vendor');
		if (inputEl) {
			inputEl.focus();
		}
	}, []);

	const onInputChange = debounce((searchString: string) => {
		track({
			event: 'Vendor Text Search',
			properties: {
				searchString,
				vendorCategory: category.name,
				vendorCategoryCode: category.code,
			},
		});
	}, 1000);

	const goToVendor: (suggestion: Raw | string) => void = (suggestion) => {
		const vendor = suggestion as Raw;
		const location = vendor?.siteUrls?.[0]?.relativeUri;
		if (location) {
			track({
				event: 'Click-through to Vendors',
				properties: {
					sourceContent: 'vendor typeahead',
					sourcePage: 'category results',
					vendorId: vendor.id,
					vendorName: vendor.name,
				},
			});
			window.location = location as unknown as Location;
		}
	};

	const labelText = `Search by ${getPromptTerm(category)} Name`;

	return (
		<VendorTypeahead
			id="search-by-vendor"
			classes={classes.vendorTypeaheadClasses}
			storefrontSearchParams={{ filters: [category.id] }}
			label={labelText}
			minLength={3}
			onInputChange={onInputChange}
			onChange={goToVendor}
			fieldClasses={classes.fieldClasses}
			renderSuggestionList={undefined}
			renderSuggestion={Suggestion}
		/>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	category: state.category,
});

export default connect(mapStateToProps)(SearchByVendor);
