const getStorefrontCardId = (vendor: Vendor.Raw): string | null =>
	vendor.storefrontCard?.id || null;

const vendorMedia = (vendor: Vendor.Raw): Vendor.Media[] => {
	const storefrontCardId = getStorefrontCardId(vendor);
	const storefrontCard = vendor.storefrontCard ? [vendor.storefrontCard] : [];

	const nonStorefrontCardPhotos = (vendor.mediaSummary.media || [])
		.filter((photo) => photo.id !== storefrontCardId)
		.filter((item) => Object.keys(item).length !== 0);

	return [...storefrontCard, ...nonStorefrontCardPhotos];
};

export default vendorMedia;
