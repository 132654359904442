import { DisplayButton } from '@xo-union/tk-ui-links';
import React, { FunctionComponent } from 'react';
import { DropdownChildProps } from '../../DropdownProps';
import Dropdown from '../../components';
import SaveButton from '../../components/SaveButton';
import Styles from '../../styles.scss';

const DesktopDropdown: FunctionComponent<DropdownChildProps> = (props) => {
	const { onClear, onSave, ...dropdownProps } = props;
	const clearSelections = () => {
		props.setStagedOptions([]);
		onClear();
	};
	return (
		<div className={Styles.dropdown}>
			<Dropdown {...dropdownProps} />
			<div className={Styles.ctaSection}>
				<DisplayButton
					onClick={clearSelections}
					className={Styles.clear}
					type="reset"
				>
					Clear
				</DisplayButton>
				<SaveButton
					disabled={false}
					onSave={onSave}
					stagedOptions={props.stagedOptions}
				>
					Save
				</SaveButton>
			</div>
		</div>
	);
};

export default DesktopDropdown;
