import { useMedia } from '@xo-union/tk-ui-breakpoints';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import DesktopSearch from './Desktop';
import MobileSearch from './Mobile';

export interface SearchProps {
	setContainerSize?: (size: number) => void;
	isMobile: boolean;
	isClientSide: boolean;
}

const Search: VFC<SearchProps> = (props) => {
	const { setContainerSize, isMobile, isClientSide } = props;

	const renderMobile =
		isMobile || (isClientSide && useMedia('(max-width: 1023px)').yes);

	if (renderMobile) {
		return <MobileSearch />;
	}

	return <DesktopSearch setContainerSize={setContainerSize} />;
};

const mapStateToProps = (state: Redux.State) => ({
	isMobile: state.viewport.isMobile,
	isClientSide: state.viewport.isClientSide,
});

export default connect(mapStateToProps)(Search);
