import useMedia from './useMedia';
import useMutableContext from './useMutableContext';

const useShouldShowPagination = () => {
	const [media] = useMedia();

	return media.length > 1;
};

export default useShouldShowPagination;
