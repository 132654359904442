import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { useContext, VFC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';

const ReviewCount: VFC = () => {
	const { reviewCount } = useContext(propsContext);
	const reviewCountClassName = useClassName('review-count');

	return (
		<span className={reviewCountClassName}>
			({reviewCount})<ScreenReaderOnly>Reviews</ScreenReaderOnly>
		</span>
	);
};

export default ReviewCount;
