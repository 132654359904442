import React, { FunctionComponent } from 'react';
import { Option } from '../DropdownProps';
import Styles from '../styles.scss';
import MultiSelectOptions from './MultiSelectOptions';
import SingleSelectOptions from './SingleSelectOptions';

interface Props {
	onSelectionChange?: (stagedOptions: string[]) => void;
	options: Option[];
	setStagedOptions: (stagedOptions: string[]) => void;
	singleSelect?: boolean;
	stagedOptions: string[];
	defaultOptions?: string[];
}

const Dropdown: FunctionComponent<Props> = ({
	onSelectionChange,
	options,
	setStagedOptions,
	stagedOptions,
	singleSelect,
	defaultOptions,
}) => {
	const selected =
		stagedOptions.length === 0 && defaultOptions
			? defaultOptions
			: stagedOptions;

	const updateSelections = (selections: string[]) => {
		if (onSelectionChange) {
			onSelectionChange(selections);
		}
		setStagedOptions(selections);
	};

	return (
		<>
			<div className={Styles.options}>
				{singleSelect ? (
					<SingleSelectOptions
						options={options}
						selected={selected}
						setSelected={updateSelections}
					/>
				) : (
					<MultiSelectOptions
						options={options}
						selected={selected}
						setSelected={updateSelections}
					/>
				)}
			</div>
		</>
	);
};

export default Dropdown;
