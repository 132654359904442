import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import React, { FunctionComponent, useState } from 'react';
import { DropdownProps } from './DropdownProps';
import DesktopDropdown from './containers/Desktop';
import MobileDropdown from './containers/Mobile';

export interface Props extends DropdownProps {
	modalHeader: string;
	isModalOpen: boolean;
	onClose: () => void;
	selectedOptions?: string[];
}

const DropdownContainer: FunctionComponent<Props> = (props) => {
	const {
		modalHeader,
		isModalOpen,
		onClose,
		selectedOptions,
		...dropdownProps
	} = props;
	const [stagedOptions, setStagedOptions] = useState(selectedOptions || []);
	const isDesktop = useDesktopMedia();

	return isDesktop.yes ? (
		<DesktopDropdown
			stagedOptions={stagedOptions}
			setStagedOptions={setStagedOptions}
			{...dropdownProps}
		/>
	) : (
		<MobileDropdown
			isModalOpen={isModalOpen}
			modalHeader={modalHeader}
			onClose={onClose}
			stagedOptions={stagedOptions}
			setStagedOptions={setStagedOptions}
			{...dropdownProps}
		/>
	);
};

export default DropdownContainer;
