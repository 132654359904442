import React, { useContext, VFC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import Item from './item';

const SecondaryInfo: VFC = () => {
	const { secondaryInfo } = useContext(propsContext);
	const secondaryInfoContainerClassName = useClassName(
		'secondary-info-container',
	);

	if (!secondaryInfo || Object.keys(secondaryInfo).length === 0) {
		return null;
	}

	return (
		<div
			className={secondaryInfoContainerClassName}
			data-testid="secondary-info-container"
		>
			{secondaryInfo.map((info, index) => (
				<Item key={info} text={info} isFirst={index === 0} />
			))}
		</div>
	);
};

export default SecondaryInfo;
