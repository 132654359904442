import type { SliderButtonProps } from '@components/vendorCard/components/Images/SliderButton';
import React, { useContext, VFC } from 'react';
import { CarouselWithStaticVisibleSlots } from '../../../../../../lib/carousel';
import type { ScrollDirection } from '../../../../vendorResult/types';
import { propsContext } from '../../../context';
import {
	useClassName,
	useHandleEvent,
	useMutableContext,
} from '../../../hooks';
import { useOrderedMedia } from '../../../hooks/useOrderedMedia';
import { SliderButtonClickEvent } from '../../../types/events';
import DefaultImage from '../DefaultImage';
import Image from '../Image';
import SliderButton from '../SliderButton';

const Media: VFC = () => {
	const { allMedia, id, maxImageCount, size } = useContext(propsContext);
	const [handleEvent] = useHandleEvent();
	const imageContainerClassName = useClassName('image-container');
	const orderedMedia = useOrderedMedia(allMedia, maxImageCount, size, id);

	const [navigationIndex, setNavigationIndex] =
		useMutableContext('navigationIndex');

	const trackClick = (
		nextIndex: number,
		carouselDirection: ScrollDirection,
	) => {
		handleEvent<SliderButtonClickEvent>({
			name: 'carousel',
			type: 'click',
			properties: {
				activeIndex: nextIndex,
				direction: carouselDirection,
				imageCount: orderedMedia.length,
			},
		});
	};

	const onNavigationIndexChange = (nextIndex: number, prevIndex: number) => {
		const direction = nextIndex > prevIndex ? 'scroll right' : 'scroll left';
		trackClick(nextIndex, direction);
		setNavigationIndex(nextIndex);
	};

	if (orderedMedia.length === 0) {
		return <DefaultImage />;
	}

	return (
		<div className={imageContainerClassName}>
			<CarouselWithStaticVisibleSlots
				visibleSlots={1}
				id={id}
				gutter={0}
				navigationIndex={0}
				pagination={1}
				onNavigationIndexChange={onNavigationIndexChange}
				terms={{
					slides: {
						plural: 'Images',
						singular: 'Image',
					},
					carousel: 'Vendor Images',
				}}
				renderNextButton={(props: SliderButtonProps) => (
					<SliderButton {...props} direction="right" />
				)}
				renderPrevButton={(props: SliderButtonProps) => (
					<SliderButton {...props} direction="left" />
				)}
			>
				{orderedMedia.map((image, index) => (
					<Image key={image.id} index={index} image={image} />
				))}
			</CarouselWithStaticVisibleSlots>
		</div>
	);
};

export default Media;
