import type { MutableRefObject } from 'react';
import { stateAbbreviationNameSlugMap } from '../../../../../helpers';
import { truncate } from '../../../../utils/truncate';

const getCityState = (location: string): string[] => {
	const [city, state] = location.split(',');
	return [city?.trim(), state?.trim()];
};

const getFullState = (stateAbbreviation?: string): string | undefined => {
	if (!stateAbbreviation) {
		return undefined;
	}

	return stateAbbreviationNameSlugMap[
		stateAbbreviation as keyof typeof stateAbbreviationNameSlugMap
	]?.stateName;
};

const truncateServiceArea = (
	containerRef: MutableRefObject<HTMLDivElement | null>,
	captionRef: MutableRefObject<HTMLDivElement | null>,
	serviceArea: string | undefined,
): string => {
	if (!serviceArea) {
		return '';
	}

	if (!containerRef.current || !captionRef.current) {
		return serviceArea;
	}

	return truncate(
		serviceArea,
		captionRef.current,
		containerRef.current.getBoundingClientRect().width - 40,
	);
};

export { getCityState, getFullState, truncateServiceArea };
