import BlankButton from '@xo-union/component-blank-button';
import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import classnames from 'classnames';
import React, { useContext, VFC } from 'react';
import { afterNextPaint } from '../../../../../helpers/after-next-paint';
import { propsContext } from '../../context';
import { useClassName, useHandleEvent } from '../../hooks';
import type { FavoriteButtonClickEvent } from '../../types/events';

const FavoriteButton: VFC = () => {
	const { size, isFavorited } = useContext(propsContext);
	const [handleEvent] = useHandleEvent();
	const buttonClassName = useClassName('favorite-button');
	const isButtonClassName = useClassName('is-favorited-button');
	const notButtonClassName = useClassName('not-favorited-button');
	const iconClassName = useClassName('favorite-icon');
	const isIconClassName = useClassName('is-favorited-icon');
	const notIconClassName = useClassName('not-favorited-icon');

	const onClick = () => {
		afterNextPaint(() => {
			handleEvent<FavoriteButtonClickEvent>({
				name: 'favorite',
				type: 'click',
				properties: {
					action: isFavorited ? 'unselect' : 'select',
				},
			});
		});
	};

	const circleClassName = classnames(
		buttonClassName,
		isFavorited ? isButtonClassName : notButtonClassName,
	);

	const childClassName = classnames(
		iconClassName,
		isFavorited ? isIconClassName : notIconClassName,
	);

	const iconName: IconName = isFavorited ? 'heart_filled' : 'heart';

	if (size === 'sm') {
		return null;
	}

	return (
		<BlankButton
			className={circleClassName}
			onClick={onClick}
			aria-label="Save"
			aria-pressed={isFavorited ? 'true' : 'false'}
		>
			<Icon
				name={iconName}
				className={childClassName}
				size="sm"
				aria-hidden="true"
			/>
		</BlankButton>
	);
};

export default FavoriteButton;
