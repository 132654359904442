import { Body1 } from '@xo-union/tk-ui-typography';
import React, { useContext, VFC } from 'react';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';

const Name: VFC = () => {
	const { name, id } = useContext(propsContext);
	const vendorNameClassName = useClassName('vendor-name');

	return (
		<Body1
			bold
			role="heading"
			aria-level={1}
			id={`vendor-id-${id}`}
			className={vendorNameClassName}
		>
			{name}
		</Body1>
	);
};

export default Name;
