import {
	RadioButton,
	SingleSelectGroup,
} from '@xo-union/tk-component-switches';
import { Caption } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React from 'react';

import Styles from '../../styles.scss';

const SingleSelectOptions = ({ options, selected, setSelected }) => {
	const handleChange = (e) => {
		const { value } = e.target;
		setSelected([value]);
	};

	return (
		<SingleSelectGroup>
			{options.map((option) => {
				const { name, value } = option;
				return (
					<div className={Styles.option} key={`option_${value}`}>
						<RadioButton
							value={value}
							checked={value === selected[0]}
							onChange={handleChange}
						>
							{name}
							{option.subtext && (
								<Caption className={Styles.caption}>{option.subtext}</Caption>
							)}
						</RadioButton>
					</div>
				);
			})}
		</SingleSelectGroup>
	);
};

SingleSelectOptions.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			subtext: PropTypes.string,
		}),
	).isRequired,
	selected: PropTypes.arrayOf(PropTypes.string).isRequired,
	setSelected: PropTypes.func.isRequired,
};

export default SingleSelectOptions;
