import { semanticSearchApiUrl } from '@settings';
import { SemanticSearchRequest, SemanticSearchResponse } from './types';

export const semanticSearch = async (requestBody: SemanticSearchRequest) => {
	const headers = new Headers();

	headers.append('Content-Type', 'application/json');

	const response = await fetch(semanticSearchApiUrl, {
		method: 'POST',
		headers,
		body: JSON.stringify(requestBody),
	});

	const data = await response.json();

	return data as SemanticSearchResponse;
};
