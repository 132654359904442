import { ClassesRecord, compose } from '@xo-union/react-css-modules';
import { FieldClasses } from '@xo-union/tk-component-fields';
import { VendorTypeaheadClasses } from '@xo-union/tk-component-vendor-typeahead';

import Styles from './styles.scss';

export const vendorTypeaheadClasses = compose<
	VendorTypeaheadClasses,
	ClassesRecord<VendorTypeaheadClasses>
>({
	'list-container': Styles.searchByVendorListContainer,
	list: Styles.searchByVendorList,
});

export const fieldClasses = compose<FieldClasses, ClassesRecord<FieldClasses>>({
	container: Styles.searchByVendor,
	input: Styles.searchByVendorInput,
});
