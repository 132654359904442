import { Caption } from '@xo-union/tk-ui-typography';
import React, { VFC } from 'react';
import { useClassName } from '../../hooks';
import ReviewCount from './reviewCount';
import ReviewScore from './reviewScore';
import ReviewStar from './reviewStar';

const Reviews: VFC = () => {
	const reviewContainerClassName = useClassName('review-container');

	return (
		<Caption role="complementary" className={reviewContainerClassName}>
			<ReviewStar />
			<ReviewScore />
			<ReviewCount />
		</Caption>
	);
};

export default Reviews;
