import badges from './badges';
import secondaryInfo from './secondaryInfo';
import starCount from './starCount';
import url from './url';
import vendorMedia from './vendorMedia';

const extract = {
	badges,
	secondaryInfo,
	starCount,
	vendorMedia,
	url,
};

export default extract;
