import { Caption } from '@xo-union/tk-ui-typography';
import React, { FunctionComponent } from 'react';
import Styles from './styles.scss';

export interface Props {
	label: string;
	onClick?: () => void;
}

const AppliedFilter: FunctionComponent<Props> = ({
	children,
	label,
	onClick,
}) => {
	const handleClick: React.MouseEventHandler<HTMLDivElement> = () =>
		onClick && onClick();
	const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = () =>
		onClick && onClick();
	const className = `${Styles.filter} ${children ? '' : Styles.withNoChildren}`;

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={className}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			role={onClick ? 'button' : undefined}
		>
			<Caption>{label}</Caption>
			{children}
		</div>
	);
};

export default AppliedFilter;
