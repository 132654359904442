import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';
import { afterNextPaint } from '../../../../../helpers/after-next-paint';
import ChangeSearchModal from './containers/ChangeSearchModal';
import Styles from './styles.scss';

interface Props {
	location: Union.Location;
	categoryPlural: string;
}

export const MobileHeader: FunctionComponent<Props> = (props) => {
	const {
		categoryPlural,
		location: { city, stateCode },
	} = props;
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const openModal = () => {
		afterNextPaint(() => {
			setModalIsOpen(true);
		});
	};
	const closeModal = () => setModalIsOpen(false);

	return (
		<Fragment>
			<div
				className={Styles.container}
				onClick={openModal}
				onKeyPress={openModal}
				role="button"
				tabIndex={0}
			>
				<div className={Styles.innerContainer} aria-label="search">
					<Body1 as="h1" className={Styles.ctaText}>
						{categoryPlural} in {city}, {stateCode}
					</Body1>
					<Icon
						aria-hidden="true"
						className={Styles.searchIcon}
						name="search"
						size="md"
					/>
				</div>
			</div>
			{modalIsOpen && (
				<ChangeSearchModal
					isMobile
					isModalOpen={modalIsOpen}
					closeCallback={closeModal}
				/>
			)}
		</Fragment>
	);
};

export const mapStateToProps = (state: Redux.State): Props => ({
	categoryPlural: state.category.plural.term,
	location: state.location,
});

export default connect(mapStateToProps)(MobileHeader);
