import type { VendorLocation } from '../../types/internal';

const vendorLocation = (
	address?: string | VendorLocation | Vendor.Address,
): string => {
	if (typeof address === 'string') {
		return address;
	}

	if (typeof address !== 'undefined') {
		return [address.city, address.state]
			.filter((location) => !!location)
			.join(', ');
	}
	return '';
};

export default vendorLocation;
