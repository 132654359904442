import search from '@api/search';
import { generateExperimentHeader } from '@server/methods/search/utils';
import { getVisitorId } from '@utils/cookies';
import { generateSeed } from '@utils/generateSeed';
import {
	DEI_FILTER_MAP,
	DISTANCE_100_FILTER,
	DISTANCE_FILTERS,
} from '../../utils/filterConstants';

export const removeDistanceFilters = (filter) =>
	!DISTANCE_FILTERS.includes(filter);

export const buildDeiFilters = (appliedIds) => {
	return DEI_FILTER_MAP.filter((filter) => appliedIds.includes(filter.id));
};

export const dedupSupplemental = (supplemental, profiles) => {
	return supplemental.filter(
		(supp) => !profiles.some((item) => item.id === supp.id),
	);
};

export const oldBrowseFilters = (categoryFilters) => {
	const filters = [];
	const categories = Object.values(categoryFilters);
	categories.forEach((cat) => cat.forEach((x) => filters.push(x.id)));
	return filters;
};

export const fetchBase =
	(actionType, options = {}) =>
	async (dispatch, getState) => {
		const seed = options.seed || generateSeed();
		dispatch({ type: actionType });

		try {
			const state = getState();
			const {
				category: { code },
				search: searchState,
				experiments,
			} = state;

			const {
				location: { city, stateCode },
				...searchOptions
			} = options;

			const headers = generateExperimentHeader(experiments);

			const searchResponse = await search(
				{
					...searchOptions,
					categoryCode: code,
					city,
					seed,
					state: stateCode,
					visitorId: getVisitorId(),
				},
				headers,
			);

			const appliedIds = state.viewport.isMobile
				? oldBrowseFilters(state.filters.categoryFilters)
				: state.search.filterPills.applied.map((filter) => filter.id);
			const deiFilters = buildDeiFilters(appliedIds);
			searchResponse.data.data.search.deiFilters = deiFilters;

			if (deiFilters.length) {
				// If DEI Filter used, do not show API supplementals
				searchResponse.data.data.search.supplemental = [];

				const { profiles } = searchResponse.data.data.search;

				// If not enough vendors returned, increase search radius and apply as supplementals
				if (profiles.length < 6 && !appliedIds.includes(DISTANCE_100_FILTER)) {
					const filters = [
						...options.filters.filter((f) => removeDistanceFilters(f)),
						DISTANCE_100_FILTER,
					];
					const newSearchResponse = await search(
						{
							...searchOptions,
							categoryCode: code,
							city,
							filters,
							seed,
							state: stateCode,
							visitorId: getVisitorId(),
						},
						headers,
					);
					const newProfiles =
						newSearchResponse?.data?.data?.search?.profiles || [];
					searchResponse.data.data.search.supplemental = dedupSupplemental(
						newProfiles,
						profiles,
					);
				}
			}

			dispatch({
				type: `${actionType}_SUCCESS`,
				payload: { ...searchResponse.data, seed },
			});

			return searchResponse;
		} catch (err) {
			dispatch({
				type: `${actionType}_ERROR`,
				payload: { errors: err.data?.errors[0].message || 'Unknown' },
			});

			return err;
		}
	};
