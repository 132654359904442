import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AnalyticsConstants from '../../../../constants/analytics';
import SUPPORTED_PVREC_CATEGORY_CODES from '../../../../constants/pvrecCategoryCodes';
import TrackableContainer from '../../../components/trackable_container';
import Vendor from '../../../decorators/vendor';
import { codeToSlugNameMap } from '../../../utils/categoryNameMappings';
import algorithmAnalytics from '../constants/algorithm_analytics';
import algorithms from '../constants/algorithms';
import CardContainer from './card_container';
import { Wrapper } from './wrapper';

export class WidgetContainer extends Component {
	header() {
		const {
			category: { code: categoryCode, name: categoryName },
		} = this.props;
		const shortNameExists =
			codeToSlugNameMap[categoryCode] &&
			codeToSlugNameMap[categoryCode].shortName;
		const headerText = shortNameExists
			? codeToSlugNameMap[categoryCode].shortName
			: categoryName;
		return `Other ${headerText} You Might\u00A0Like`;
	}

	noValidAlgorithm() {
		const { recommendedVendors } = this.props;
		return !recommendedVendors[algorithms.PVREC_ANON];
	}

	widgetViewedTrackingData() {
		const trackingProps = {
			...this.commonTrackingData(),
			event: AnalyticsConstants.WIDGET_VIEWED,
		};

		return trackingProps;
	}

	commonTrackingData() {
		const {
			marketCode,
			recommendedVendors,
			algorithmType,
			category: { code: categoryCode },
		} = this.props;
		const vendors = recommendedVendors[algorithmType];
		if (!vendors || !vendors.length > 0) {
			return {
				properties: {},
			};
		}
		const recommendedVendor = new Vendor(vendors[0]);
		const analytics = algorithmAnalytics[algorithmType];

		return {
			properties: {
				category_code:
					recommendedVendor.categoryCode === ''
						? categoryCode
						: recommendedVendor.categoryCode,
				displayMarketCode: marketCode,
				pagePosition: analytics.position,
				sourceChannel: 'internal',
				sourceContent: 'recommended vendors',
				sourceFormula: 'pvRec anon',
				sourcePage: 'category results',
				vendorCategory: recommendedVendor.categoryCode,
				vendorCount: recommendedVendors[algorithmType].length,
				widgetName: 'right rail module',
			},
		};
	}

	render() {
		const {
			algorithmType,
			cardType,
			category,
			className,
			maxResultColumns,
			recommendedVendors,
		} = this.props;

		if (
			this.noValidAlgorithm() ||
			!SUPPORTED_PVREC_CATEGORY_CODES.includes(category.code) ||
			maxResultColumns === 4
		) {
			return null;
		}

		return (
			<TrackableContainer trackingData={this.widgetViewedTrackingData()}>
				<Wrapper
					className={className}
					headerText={this.header()}
					seeAllUrl=""
					cardType={cardType}
					trackingData={this.commonTrackingData()}
					algorithmType={algorithmType}
				>
					<CardContainer
						algorithmType={algorithmType}
						impressionType="Rec Module: Directory - Right Rail Recs"
						recommendedVendors={recommendedVendors[algorithmType]}
						cardType={cardType}
						trackingData={this.commonTrackingData()}
						displayTheme="city"
					/>
				</Wrapper>
			</TrackableContainer>
		);
	}
}

export const propTypes = {
	algorithmType: PropTypes.oneOf([algorithms.PVREC_ANON]),
	cardType: PropTypes.oneOf(['sm', 'lg']),
	className: PropTypes.string,
	category: PropTypes.object.isRequired,
	fetchPvRecAnonVendors: PropTypes.func.isRequired,
	marketCode: PropTypes.string,
	maxResultColumns: PropTypes.number,
	location: PropTypes.object.isRequired,
	recommendedVendors: PropTypes.arrayOf(PropTypes.object),
};

WidgetContainer.propTypes = propTypes;

WidgetContainer.defaultProps = {
	algorithmType: algorithms.PVREC_ANON,
	cardType: 'sm',
	className: '',
	marketCode: '',
	recommendedVendors: [],
};

export default WidgetContainer;
