import type { SecondaryVendorFacet } from '@components/vendorCard/types';

const PRICE_RANGE_FACET_ID = 'c6046f9a-5780-4b76-8ace-731ff1b7ae62';
const STARTING_PRICE_RANGE_FACET_ID = 'd152eee4-6a3f-4116-9226-8a977d274b85';
const GUEST_CAPACITY_FACET_ID = '44ed7871-6e3c-4105-806a-4fd9efe72c4e';
const OUTDOOR_EVENT_SPACE_FACET_ID = 'a08cd584-a729-4c79-bcfc-93170d296b9d';
const VENUE_AMENITIES_FACET_ID = 'c1b91e1f-a8ac-4896-9e7f-fcbbad603c5b';

const getCostTierText = (costTier?: string): string => {
	switch (costTier) {
		// attention these are a '–' not '-'
		case '$ – Inexpensive':
			return '$';
		case '$$ – Affordable':
			return '$$';
		case '$$$ – Moderate':
			return '$$$';
		case '$$$$ – Luxury':
			return '$$$$';
		default:
			return '';
	}
};

const formatStartingPrice = (price: string): string =>
	price.split('-').join(' - ');

const buildFacetIds = (vendor: Vendor.Raw): SecondaryVendorFacet[] => {
	const category = vendor.categories[0];
	if (!category) {
		return [];
	}
	switch (category.code) {
		case 'REC':
			return [
				{
					facetId: GUEST_CAPACITY_FACET_ID,
					format: (capacity: string) =>
						capacity === '' ? '' : `${capacity} Guests`,
				},
				{ facetId: PRICE_RANGE_FACET_ID, format: getCostTierText },
				{
					facetId: VENUE_AMENITIES_FACET_ID,
					subFacet: OUTDOOR_EVENT_SPACE_FACET_ID,
				},
			];
		case 'WPH':
			return [
				{ facetId: STARTING_PRICE_RANGE_FACET_ID, format: formatStartingPrice },
			];
		default:
			return [PRICE_RANGE_FACET_ID];
	}
};

export default buildFacetIds;
