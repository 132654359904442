import React, { FunctionComponent, Fragment } from 'react';

import { buildAddress } from '../helpers';
import Styles from './styles.scss';

const Suggestion: FunctionComponent<Vendor.Raw> = (props) => {
	const {
		name,
		location: { address },
	} = props;

	const location = buildAddress(address);

	return (
		<Fragment>
			<div>
				<div className={Styles.suggestionMain}>{name}</div>
				<div className={Styles.suggestionSecondary}>{location}</div>
			</div>
		</Fragment>
	);
};

export default Suggestion;
