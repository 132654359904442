const primitive = <T>(fallback: T, defaultValueProvided?: T, value?: T) => {
	if (typeof value === 'undefined') {
		if (typeof defaultValueProvided === 'undefined') {
			return fallback;
		}
		return defaultValueProvided;
	}

	return value;
};

export const array = <T>(value?: T[], defaultValue?: T[]): T[] =>
	primitive([], defaultValue, value);
export const boolean = (value?: boolean, defaultValue?: boolean): boolean =>
	primitive(false, defaultValue, value);
export const number = (value?: number, defaultValue?: number): number =>
	primitive(0, defaultValue, value);
export const object = <T>(value?: T, defaultValue?: T): T =>
	primitive({}, defaultValue, value) as T;
export const string = (value?: string, defaultValue?: string): string =>
	primitive('', defaultValue, value);
