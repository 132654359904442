/**
 * Generates an array of IDs with a length of the number of media items or 5, whichever is smaller.
 *
 * @param {Media[]} media
 * @return {string[]}
 */
const createIconArray = (media: Vendor.Media[]): string[] => {
	const { length } = media;
	const size = Math.min(length, 5);

	return Array.from({ length: size })
		.map((_, i) => media[i].id)
		.filter((id): id is string => !!id);
};

export default createIconArray;
