import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { AsyncTypeahead } from '@xo-union/tk-component-fields';
import debounce from 'lodash/debounce';
import React, {
	ChangeEvent,
	FocusEvent,
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Track } from 'types/analytics';
import { setCookieLocation } from '../../../../utils/cookie/location';
import locationToString from '../../../../utils/locationToString';
import { getRandomKey, searchGeo } from '../helpers';
import ClearButton from './ClearButton';
import Suggestion from './Suggestion';
import * as classes from './classes';

const trackTypingInput = (searchString: string, track: Track) => {
	track({
		event: 'Vendor Search Interaction',
		properties: {
			action: 'typing',
			searchString,
			sourceContent: 'search geography',
			sourcePage: 'category results',
		},
	});
};
const debouncedTrackTypingInput = debounce(trackTypingInput, 1000);

export interface LocationProps {
	defaultCityState: string;
	setLocationHash: (hash: Union.Location) => void;
	checkUserInput: () => void;
	locationHash: Union.Location | null;
}

export const Location: FunctionComponent<LocationProps> = (props) => {
	const { checkUserInput, setLocationHash, defaultCityState, locationHash } =
		props;
	const pageType = useAppSelector((state) => state.page.pageType);
	const [hoverClear, setHoverClear] = useState<boolean>(false);
	const [locations, setLocations] = useState<Union.Location[]>([]);
	const [defaultLocations, setDefaultLocations] = useState<Union.Location[]>(
		[],
	);
	const [randomKey, setRandomKey] = useState<number>(getRandomKey());
	const [showClear, setShowClear] = useState<boolean>(false);
	const [defaultValue, setDefaultValue] = useState<string>(defaultCityState);
	const [currentInput, setCurrentInput] = useState<string>(defaultCityState);
	const [hasClearedLocationField, setHasClearedLocationField] = useState(false);
	const { track } = useAnalyticsContext();

	// Get locations for default location to display dropdown on focus
	const setupLocations = useCallback(async () => {
		const defaultSearchLocations = await searchGeo(defaultCityState);
		setLocations(defaultSearchLocations);
	}, [defaultCityState]);

	const setFocus = () => {
		// We want to wait for remount, so we will only fire when the randomKey is changed
		// Only want to focus when the show clear is clicked so it doesn't focus on mount.
		if (showClear) {
			// Focus the input
			const inputEl = document.getElementById('location-search');
			if (inputEl) {
				inputEl.focus();
			}
			// Hide the close circle
			clear();
		}
	};

	useEffect(() => {
		setupLocations();
	}, [setupLocations]);

	const handleSearch = async (query: string) => {
		const geoLocations = await searchGeo(query);
		setLocations(geoLocations);
	};

	const onFocus = async (event: FocusEvent<HTMLInputElement>) => {
		const currentLocation = locationToString(locationHash);
		event.target.setSelectionRange(
			currentLocation.length,
			currentLocation.length,
		);

		if (
			!locationHash ||
			locationHash.city !== null ||
			currentInput.length > 0
		) {
			setShowClear(true);
		}

		// If it is the first focus
		if (defaultValue === defaultCityState) {
			setDefaultValue('');
		}

		// Get locations for no query, to be ready for onClear
		const defaultEmptyLocations = await searchGeo('');
		setDefaultLocations(defaultEmptyLocations);
	};

	const onClear = () => {
		// Set the locations to the default 100
		setLocations(defaultLocations);
		// Clear the selected location
		setLocationHash({
			city: null,
			stateCode: null,
		});

		// Change the key randomly so it remounts
		const key = getRandomKey();
		setRandomKey(key);
		setFocus();
	};

	const onBlur = () => {
		if (!hoverClear) {
			clear();
		}
	};

	const onInput = (data: ChangeEvent<HTMLInputElement>) => {
		const { value } = data.target;

		if (value === '') {
			setHasClearedLocationField(true);
		}

		if (pageType === 'city' && hasClearedLocationField) {
			debouncedTrackTypingInput(value, track);
		}

		setCurrentInput(value);
		if (value.length > 0) {
			setShowClear(true);
		}
		checkUserInput();
	};

	const clear = () => {
		setShowClear(false);
		setCurrentInput('');
	};

	const selectLocation = (hash: Union.Location) => {
		setCookieLocation(hash);
		setLocationHash(hash);
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action: 'location selected',
				sourcePage: 'category results',
				vendorMarket: locationToString(hash),
			},
		});
	};

	return (
		<>
			<AsyncTypeahead
				key={randomKey}
				id="location-search"
				classes={classes.asyncTypeaheadClasses}
				suggestions={locations}
				labelKey={(location: Union.Location) => locationToString(location)}
				onSearch={handleSearch}
				onChange={selectLocation}
				onBlur={onBlur}
				label="Location"
				name="location"
				defaultInputValue={defaultValue}
				onInput={onInput}
				onFocus={onFocus}
				renderSuggestion={Suggestion}
			/>
			{showClear && (
				<ClearButton setHoverClear={setHoverClear} onClick={onClear} />
			)}
		</>
	);
};

export default Location;
