import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { useContext, VFC } from 'react';
import { propsContext } from '../../context';
import { useClassName, useHandleEvent } from '../../hooks';
import type { VendorBadge } from '../../types';
import Badges from '../Badges';
import Name from '../Name';
import RequestPricingButton from '../RequestPricingButton';
import Reviews from '../Reviews';
import SecondaryInfo from '../SecondaryInfo';
import VendorLocation from '../VendorLocation';
import VenueBlurb from '../VenueBlurb';

interface InformationProps {
	badges: VendorBadge[];
}

const Information: VFC<InformationProps> = ({ badges }) => {
	const { size, url } = useContext(propsContext);
	const infoContainerClassName = useClassName('info-container');
	const subtextClassName = useClassName('subtext');
	const [handleEvent] = useHandleEvent();

	const onClick = () => {
		handleEvent({
			name: 'card',
			type: 'click',
		});
	};

	return (
		// eslint-disable-next-line react/jsx-no-target-blank
		<a
			href={url}
			onClick={onClick}
			className={infoContainerClassName}
			rel="noreferrer noopener"
			target="_blank"
		>
			<ScreenReaderOnly>Vendor Details</ScreenReaderOnly>
			<div className={subtextClassName}>
				<VendorLocation />
				<Reviews />
			</div>
			<Name />
			<SecondaryInfo />
			<VenueBlurb />
			{size === 'lg' && <Badges badges={badges} />}
			{size === 'sm' && <RequestPricingButton />}
			<ScreenReaderOnly>View Vendor Storefront</ScreenReaderOnly>
		</a>
	);
};

export default Information;
