import React, { forwardRef } from 'react';
import { useClassName } from '../../hooks';
import { VendorCardLargeProps } from '../../types';
import FavoriteButton from '../FavoriteButton';
import Images from '../Images';
import Information from '../Information';

const Content = forwardRef<HTMLDivElement, VendorCardLargeProps>(
	(props, ref) => {
		const className = useClassName('content');
		const { badges = [] } = props;

		return (
			<div ref={ref} className={className} data-testid="content-container">
				<Images />
				<Information badges={badges} />
				<FavoriteButton />
			</div>
		);
	},
);

export default React.memo(Content);
