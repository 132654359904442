/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	REACT_CONTEXT_TYPE,
	REACT_ELEMENT_TYPE,
	REACT_FORWARD_REF_TYPE,
	REACT_FRAGMENT_TYPE,
	REACT_LAZY_TYPE,
	REACT_MEMO_TYPE,
	REACT_PORTAL_TYPE,
	REACT_PROFILER_TYPE,
	REACT_PROVIDER_TYPE,
	REACT_STRICT_MODE_TYPE,
	REACT_SUSPENSE_TYPE,
} from './react';

export function typeOf(object: any) {
	if (typeof object === 'object' && object !== null) {
		const { $$typeof } = object;
		// eslint-disable-next-line default-case
		switch ($$typeof) {
			case REACT_ELEMENT_TYPE: {
				const { type } = object;

				switch (type) {
					case REACT_FRAGMENT_TYPE:
					case REACT_PROFILER_TYPE:
					case REACT_STRICT_MODE_TYPE:
					case REACT_SUSPENSE_TYPE:
						return type;
					default: {
						const $$typeofType = type && type.$$typeof;

						switch ($$typeofType) {
							case REACT_CONTEXT_TYPE:
							case REACT_FORWARD_REF_TYPE:
							case REACT_LAZY_TYPE:
							case REACT_MEMO_TYPE:
							case REACT_PROVIDER_TYPE:
								return $$typeofType;
							default:
								return $$typeof;
						}
					}
				}
			}
			case REACT_PORTAL_TYPE:
				return $$typeof;
		}
	}

	return undefined;
}

export const ContextConsumer = REACT_CONTEXT_TYPE;
export const ContextProvider = REACT_PROVIDER_TYPE;
export const Element = REACT_ELEMENT_TYPE;
export const ForwardRef = REACT_FORWARD_REF_TYPE;
export const Fragment = REACT_FRAGMENT_TYPE;
export const Lazy = REACT_LAZY_TYPE;
export const Memo = REACT_MEMO_TYPE;
export const Portal = REACT_PORTAL_TYPE;
export const Profiler = REACT_PROFILER_TYPE;
export const StrictMode = REACT_STRICT_MODE_TYPE;
export const Suspense = REACT_SUSPENSE_TYPE;

export function isContextConsumer(object: any) {
	return typeOf(object) === REACT_CONTEXT_TYPE;
}
export function isContextProvider(object: any) {
	return typeOf(object) === REACT_PROVIDER_TYPE;
}
export function isElement(object: any) {
	return (
		typeof object === 'object' &&
		object !== null &&
		object.$$typeof === REACT_ELEMENT_TYPE
	);
}
export function isForwardRef(object: any) {
	return typeOf(object) === REACT_FORWARD_REF_TYPE;
}
export function isFragment(object: any) {
	return typeOf(object) === REACT_FRAGMENT_TYPE;
}
export function isLazy(object: any) {
	return typeOf(object) === REACT_LAZY_TYPE;
}
export function isMemo(object: any) {
	return typeOf(object) === REACT_MEMO_TYPE;
}
export function isPortal(object: any) {
	return typeOf(object) === REACT_PORTAL_TYPE;
}
export function isProfiler(object: any) {
	return typeOf(object) === REACT_PROFILER_TYPE;
}
export function isStrictMode(object: any) {
	return typeOf(object) === REACT_STRICT_MODE_TYPE;
}
export function isSuspense(object: any) {
	return typeOf(object) === REACT_SUSPENSE_TYPE;
}
