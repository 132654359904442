import SmallCategoryCard from '@components/shared/SmallCategoryCard';
import categoryMediaMappings from '@utils/categoryMediaMappings';
import { Column, Row } from '@xo-union/tk-component-grid';
import { H3 } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import CategoriesConstants from '../../../constants/categories';
import Styles from './styles.scss';

export const FooterCategories = (props) => {
	const { city, footerCategories, stateCode } = props;

	return (
		<Fragment>
			<Row>
				<Column xs="12">
					<H3 className={Styles.heading}>Wedding Vendors in {city}</H3>
				</Column>
			</Row>
			<Row vGutters>
				{footerCategories.map((category) => (
					<Column key={category.id} xs="12" sm="6" md="4">
						<SmallCategoryCard
							categoryCode={category.code}
							categoryName={category.name}
							categorySlug={category.urlSlug}
							city={city}
							key={category.code}
							mediaApiId={categoryMediaMappings[category.code].mediaId}
							stateCode={stateCode}
						/>
					</Column>
				))}
			</Row>
		</Fragment>
	);
};

FooterCategories.propTypes = {
	city: PropTypes.string.isRequired,
	footerCategories: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			urlSlug: PropTypes.string.isRequired,
		}),
	),
	stateCode: PropTypes.string.isRequired,
};

// default "top 10" categories to display
FooterCategories.defaultProps = {
	footerCategories: CategoriesConstants.TOP_TEN_CATEGORIES,
};

export default FooterCategories;
