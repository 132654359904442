import type {
	MutableContextState,
	MutableContextValue,
} from '../types/context';
import NOOP from './noop';

export const DEFAULT_MUTABLE_VALUE: MutableContextValue = {
	isHovering: false,
	invalidMediaIds: [],
	navigationIndex: 0,
};

export const DEFAULT_MUTABLE_CONTEXT: MutableContextState = {
	value: DEFAULT_MUTABLE_VALUE,
	setValue: NOOP,
	setContextValue: NOOP,
};
