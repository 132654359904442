import { quickResponderAssignmentSelector } from '@redux/experiments/selectors/quick-responder';
import { useAppSelector } from '@redux/hooks';
import React, { forwardRef, useContext, useMemo } from 'react';
import * as BADGES from '../../constants';
import { propsContext } from '../../context';
import { useClassName } from '../../hooks';
import { VendorCardLargeProps } from '../../types';
import QuickResponderBadge from '../Badges/QuickResponderBadge';
import Content from '../Content';
import RequestPricingButton from '../RequestPricingButton';

const VendorCardBase = forwardRef<HTMLDivElement, VendorCardLargeProps>(
	(_props, ref) => {
		const { badges, size, id, quickResponder } = useContext(propsContext);
		const className = useClassName('container');
		const isInQuickResponderAssignment: boolean =
			useAppSelector((state) => quickResponderAssignmentSelector(state)) ===
			'quick-responder';
		const isInQuickResponderBadgeAssignment: boolean =
			useAppSelector((state) => quickResponderAssignmentSelector(state)) ===
			'quick-responder-badge';

		const updatedBadges = useMemo(() => {
			if (
				quickResponder &&
				isInQuickResponderBadgeAssignment &&
				badges?.length >= 0
			) {
				return [BADGES.QUICK_RESPONDER, ...badges];
			}
			return badges;
		}, [badges, quickResponder, isInQuickResponderBadgeAssignment]);

		return (
			<>
				<section
					ref={ref}
					className={className}
					aria-labelledby={`vendor-id-${id}`}
					data-testid="vendor-card-base"
				>
					<Content badges={updatedBadges} />
					{size !== 'sm' && <RequestPricingButton />}
				</section>
				{quickResponder && isInQuickResponderAssignment && (
					<QuickResponderBadge />
				)}
			</>
		);
	},
);

export default VendorCardBase;
