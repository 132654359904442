import { StatusBadge } from '@xo-union/tk-component-badges';
import React, { VFC } from 'react';
import { useClassName } from '../../hooks';
import type { VendorBadge } from '../../types';

interface BadgesProps {
	badges: VendorBadge[];
}

const Badges: VFC<BadgesProps> = ({ badges }) => {
	const containerClassName = useClassName('badge-container');
	const itemClassName = useClassName('badge-item');

	if (badges.length === 0) {
		return null;
	}

	return (
		<ul className={containerClassName}>
			{badges.map((badge) => (
				<li key={badge.label} className={itemClassName}>
					<StatusBadge icon={badge.icon} type={badge.type || 'attribute'}>
						{badge.label}
					</StatusBadge>
				</li>
			))}
		</ul>
	);
};

export default Badges;
