import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import React, { VFC } from 'react';

export interface ServiceAreaProps {
	serviceArea?: string;
	truncatedServiceArea?: string;
}

const ServiceArea: VFC<ServiceAreaProps> = (props) => {
	const { serviceArea, truncatedServiceArea } = props;

	if (!serviceArea || serviceArea.length === 0) {
		return null;
	}

	const renderedServiceArea = truncatedServiceArea || serviceArea;

	return (
		<>
			<span aria-hidden="true">{renderedServiceArea}</span>
			<ScreenReaderOnly>{serviceArea}</ScreenReaderOnly>
		</>
	);
};

export default ServiceArea;
