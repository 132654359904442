import { selectAssignment } from '@redux/experiments/selectors';
import { useAppSelector } from '@redux/hooks';
import { Body2 } from '@xo-union/tk-ui-typography';
import React, { VFC, useContext, useMemo } from 'react';
import { propsContext } from '../../context';

import Styles from './styles.scss';

const VenueBlurb: VFC = () => {
	const { dek } = useContext(propsContext);
	const blurbsAssignment = useAppSelector((state) =>
		selectAssignment(state, ['tkNoContentBlurbs']),
	);

	const blurbsClass = useMemo(() => {
		if (blurbsAssignment === 'no-content-blurbs') return null;
		if (blurbsAssignment === 'blurb-no-fade') return Styles.blurbNoFade;
		if (blurbsAssignment === 'blurb-ellipsis') return Styles.blurbEllipsis;
		return Styles.container;
	}, [blurbsAssignment]);

	if (blurbsClass === null) return null;

	return <Body2 className={blurbsClass}>{dek?.description}</Body2>;
};

export default VenueBlurb;
