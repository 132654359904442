/* eslint-disable no-console */

import { Props } from '../../types/context';

const imageWarnings = (props: Props) => {
	if (
		typeof props.lazyLoadImage !== 'undefined' &&
		typeof props.lazyLoadImages !== 'undefined'
	) {
		console.warn(
			'`props.lazyLoadImage` has been deprecated. `props.lazyLoadImages` will be used instead.',
		);
	}

	if (
		typeof props.lazyLoadImage !== 'undefined' &&
		typeof props.lazyLoadImages === 'undefined'
	) {
		console.warn(
			'`props.lazyLoadImage` has been deprecated. Please use `props.lazyLoadImages`.',
		);
	}

	if (typeof props.imageAlt !== 'undefined') {
		console.warn(
			'`props.imageAlt` has been deprecated. This will have no effect on the component.',
		);
	}
};

const mediaWarning = (props: Props) => {
	const hasMedia = props.media && props.media.length > 0;
	if (hasMedia) {
		if (typeof props.imageUrl !== 'undefined') {
			console.warn(
				'`props.imageUrl` has been passed along with a media array. This will have no effect on the component.',
			);
		}
	}
};

const otherWarning = (props: Props, key: keyof Props) => {
	if (typeof props[key] !== 'undefined') {
		console.warn(
			`\`props.${key}\` has been deprecated. This will have no effect on the component.`,
		);
	}
};

export const deprecatedWarnings = (props: Props) => {
	imageWarnings(props);
	mediaWarning(props);
	otherWarning(props, 'infoStatement');
	otherWarning(props, 'isFeatured');
};

export const missingProps = (baseContextValue: Props) => {
	const { name, id } = baseContextValue;
	if (!name || name === '') {
		console.error(
			`The vendor card with the id \`${id}\` is missing a name. Please provide \`props.name\`.`,
		);
	}

	if (!id || id === '') {
		console.error(
			`The vendor card with the name \`${name}\` is missing an ID. Please provide \`props.id\`.`,
		);
	}
};
