import { Button } from '@xo-union/tk-component-buttons';
import React, {
	memo,
	useCallback,
	useContext,
	useMemo,
	useRef,
	VFC,
} from 'react';
import { propsContext } from '../../context';
import { useClassName, useHandleEvent } from '../../hooks';

const RequestPricingButton: VFC = memo(() => {
	const { ctaLabel, hideRequestPricing, size, isMobile } =
		useContext(propsContext);
	const [handleEvent] = useHandleEvent();

	const requestPricingClassName = useClassName('request-pricing');
	const requestPricingWrapperClassName = useClassName(
		'request-pricing-wrapper',
	);

	const buttonRef = useRef<HTMLSpanElement>(null);

	const color = useMemo(() => {
		if (size === 'lg') {
			return isMobile ? 'primary' : 'secondary';
		}

		return 'tertiary';
	}, [size, isMobile]);

	const onClick = useCallback(() => {
		handleEvent({
			name: 'request',
			type: 'click',
		});
	}, [handleEvent]);

	if (hideRequestPricing) {
		return null;
	}

	return (
		<div className={requestPricingWrapperClassName}>
			<Button
				aria-label={ctaLabel}
				ref={buttonRef}
				onClick={onClick}
				color={color}
				size={size === 'lg' ? 'md' : 'sm'}
				className={requestPricingClassName}
			>
				{ctaLabel}
			</Button>
		</div>
	);
});

export default RequestPricingButton;
