import type Vendor from '../../../../../../types/vendor';
import * as BADGES from '../../../constants/badges';
import { BOW_FACET_ID } from '../../../constants/facets';
import type { VendorBadge } from '../../../types';
import * as primitives from '../../../utilities/primitives';

export const shouldShowBowBadge = (vendor?: Vendor.Raw): boolean => {
	const isPaid = vendor?.purchaseStatus === 'PAID';
	const isIntro = vendor?.vendorTier === 'INTRO';

	return isPaid && !isIntro;
};

const bow = (vendor?: Vendor.Raw): Vendor.Affiliate | null => {
	if (!vendor || !vendor.affiliates || !shouldShowBowBadge(vendor)) {
		return null;
	}

	return (
		vendor.affiliates.find((affiliate) => affiliate.id === BOW_FACET_ID) || null
	);
};

const hasVirtualTour = (vendor: Vendor.Raw): boolean => {
	const media = vendor.mediaSummary?.media || [];
	const vendorVirtualTour = media.some(
		(image) => image.mediaType === '360TOUR',
	);
	return primitives.boolean(vendorVirtualTour);
};

const badges = (vendor: Vendor.Raw): VendorBadge[] => {
	const vendorBadges: VendorBadge[] = [];

	const isBow = !!bow(vendor);
	if (isBow) {
		vendorBadges.push(BADGES.BOW_BADGE);
	}

	const vendorHasVirtualTour = hasVirtualTour(vendor);
	if (vendorHasVirtualTour) {
		vendorBadges.push(BADGES.VIRTUAL_TOUR_BADGE);
	}

	return vendorBadges;
};

export default badges;
