export const getSelectedDotIndex = (
	navigationIndex: number,
	mediaLength: number,
	isMobile: boolean,
) => {
	if (!isMobile || mediaLength <= 5 || navigationIndex < 2)
		return navigationIndex;
	if (navigationIndex === mediaLength - 1) return 4;
	if (navigationIndex === mediaLength - 2) return 3;
	return 2;
};
