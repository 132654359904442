import React, { createContext, FunctionComponent, useState } from 'react';
import { DEFAULT_MUTABLE_CONTEXT, DEFAULT_MUTABLE_VALUE } from '../constants';
import type {
	MutableContextState,
	MutableContextValue,
} from '../types/context';

const MutableContext = createContext<MutableContextState>(
	DEFAULT_MUTABLE_CONTEXT,
);

export const MutableContextProvider: FunctionComponent<
	Partial<MutableContextValue>
> = (props) => {
	const { children, ...context } = props;
	const baseValue = { ...DEFAULT_MUTABLE_VALUE, ...context };
	const [contextValue, setContextValue] =
		useState<MutableContextValue>(baseValue);

	const setValue = (value: Partial<MutableContextValue>): void => {
		setContextValue((prevValue) => ({
			...prevValue,
			...value,
		}));
	};

	const value: MutableContextState = {
		value: contextValue,
		setValue,
		setContextValue,
	};

	return (
		<MutableContext.Provider value={value}>{children}</MutableContext.Provider>
	);
};

export default MutableContext;
