import roundReviewRating from '../../../utilities/roundReviewRating';

const starCount = (vendor: Vendor.Raw): number => {
	const overallRating = vendor.reviewSummary?.overallRating;
	if (typeof overallRating !== 'undefined') {
		const count = roundReviewRating(overallRating);
		return count;
	}

	return 0;
};

export default starCount;
