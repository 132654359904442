import createIconArray from './createIconArray';
import getClassName from './getClassName';
import { getSelectedDotIndex } from './getSelectedDotIndex';

const utilities = {
	createIconArray,
	getClassName,
	getSelectedDotIndex,
};

export default utilities;
