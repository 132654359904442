import { useContext } from 'react';
import { mutableContext, propsContext } from '../../context';
import { getMedia } from './getMedia';

interface RemoveMedia {
	(media: Vendor.Media): void;
}

type UseMediaReturn = [Vendor.PhotoMedia[], RemoveMedia];

interface UseMedia {
	(): UseMediaReturn;
}

const useMedia: UseMedia = () => {
	const { allMedia, maxImageCount, size, preserveBrokenImages } =
		useContext(propsContext);
	const {
		value: { invalidMediaIds },
		setValue,
	} = useContext(mutableContext);

	const extractedMedia = getMedia({
		maxImageCount,
		media: allMedia,
		size,
		invalidIds: invalidMediaIds,
	});

	const removeMedia: RemoveMedia = (media) => {
		if (!preserveBrokenImages) {
			setValue({
				invalidMediaIds: media.id
					? [...invalidMediaIds, media.id]
					: invalidMediaIds,
			});
		}
	};

	return [extractedMedia, removeMedia];
};

export default useMedia;
