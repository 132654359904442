import { useClasses } from '@xo-union/react-css-modules';
import { useContext } from 'react';
import { propsContext } from '../context';
import buildDefaultClasses from '../context/getClassNames/vendorCard/buildDefaultClasses';
import type { VendorCardClassesActive } from '../types/classes';

interface UseClassName {
	(name: keyof VendorCardClassesActive): string | undefined;
}

const useClassName: UseClassName = (name) => {
	const { size, classes } = useContext(propsContext);
	const defaultClasses = buildDefaultClasses(size);
	const builtClasses = useClasses(defaultClasses, classes);

	const className = builtClasses[name];

	return className;
};

export default useClassName;
