import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Select, SelectChangeEvent } from '@xo-union/tk-component-fields';
import { debounce } from 'lodash';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { sortByFeatured } from '../../../../utils/featuredCategoryCodes';
import { getAvailableWidth, getElement, setNameEllipses } from '../helpers';
import SelectOption from './SelectOption';
import * as classes from './classes';

export interface Props {
	categories: Redux.Categories;
	setCode: (code: string) => void;
	code: string | null;
}

const SelectCategory: FunctionComponent<Props> = (props) => {
	const { categories, code, setCode } = props;
	const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
	const selectRef = useRef<HTMLSpanElement | null>(null);
	const [localCategories, setLocalCategories] = useState(categories);
	const { track } = useAnalyticsContext();
	useEffect(() => {
		window.addEventListener('resize', debounceBuildLocalCategories);
		return () => {
			window.removeEventListener('resize', debounceBuildLocalCategories);
		};
	}, []);
	useEffect(() => {
		if (selectRef.current) {
			buildLocalCategories();
		}
	}, [selectRef]);
	const buildLocalCategories = () => {
		if (selectRef.current) {
			const currentCategories = setNameEllipses(
				sortByFeatured(categories),
				getAvailableWidth(selectRef),
				getElement(selectRef),
			);
			setLocalCategories(currentCategories);
		}
	};
	const debounceBuildLocalCategories = debounce(buildLocalCategories, 100);
	const trackCategoryMenuOpen = useCallback(() => {
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action: 'open_category_menu',
				sourceContent: 'category_choice',
				sourcePage: 'category results',
			},
		});
	}, [track]);
	const handleClose = () => {
		setCategoryMenuOpen(false);
	};

	const handleOpen = () => {
		setCategoryMenuOpen(true);
		trackCategoryMenuOpen();
	};
	const handleCategorySelection = (data: SelectChangeEvent) => {
		const { value } = data.option;
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action: 'select category',
				sourcePage: 'category results',
				vendorCategoryCode: value,
			},
		});
		setCode(value);
	};

	return (
		<span ref={selectRef}>
			<Select
				id="vendor_category"
				listBoxId="vendor_category"
				labelId="vendor_category"
				label="Category"
				name="vendor_category"
				isOpen={categoryMenuOpen}
				onChange={handleCategorySelection}
				onOpen={handleOpen}
				onClose={handleClose}
				value={code || undefined}
				classes={classes.selectClasses}
			>
				{localCategories.map((category) => (
					<SelectOption
						key={category.id}
						category={category}
						label={category.name}
					/>
				))}
			</Select>
		</span>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	categories: state.categories,
});

export default connect(mapStateToProps)(SelectCategory);
